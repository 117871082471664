import { SignalType } from "@/lib/signals";
import { AsyncLoadStatusType } from "./loader";
import { useSignals } from "@preact/signals-react/runtime";
export function AsyncLoaderComponent({
  status,
  error
}: {
  status: SignalType<AsyncLoadStatusType>;
  error: SignalType<Error | null>;
}) {
  useSignals();
  if (status.value === "ready" || status.value === "refreshing") {
    return null;
  }
  if (status.value === "error") {
    return <div>Error loading: {error.value?.message || "unknown error"}</div>;
  }
  return <div data-sentry-component="AsyncLoaderComponent" data-sentry-source-file="loadercomponent.tsx">Loading...</div>;
}