"use client";

import { ReactNode, useEffect, useState } from "react";
import { initDeferred } from "./loader";
import { initDeferedSignals } from "@/lib/signals";
import { computed, effect, signal } from "@preact/signals-react";
import { useRouter, useSelectedLayoutSegments } from "next/navigation";
export const hashParamsSignal = signal(new URLSearchParams(""));
export function updateHashParams(params: Record<string, string | boolean | null | undefined>) {
  const newParams = new URLSearchParams(hashParamsSignal.value);
  for (const [key, value] of Object.entries(params)) {
    if (!value) {
      newParams.delete(key);
      continue;
    }
    if (value === true) {
      newParams.set(key, "");
    } else {
      newParams.set(key, value);
    }
  }
  hashParamsSignal.value = newParams;
  if (typeof window === "undefined") {
    console.info("Not updating hash params on server");
  } else {
    window.location.hash = "#" + newParams.toString();
  }
}
export function withUpdatedHashParam(params: Record<string, string | boolean | null | undefined>) {
  return computed(() => {
    const newParams = new URLSearchParams(hashParamsSignal.value);
    for (const [key, value] of Object.entries(params)) {
      if (!value) {
        newParams.delete(key);
        continue;
      }
      if (value === true) {
        newParams.set(key, "");
      } else {
        newParams.set(key, value);
      }
    }
    return newParams;
  });
}
export function linkWithUpdatedHashParam(params: Record<string, string | boolean | null | undefined>) {
  return `#${withUpdatedHashParam(params).value}`;
}
export const singleHashParamSignal = (key: string) => {
  const s = signal<string | null>(null);
  effect(() => {
    const val = hashParamsSignal.value.get(key);
    if (!_hashParamsInitialized) {
      return;
    }
    s.value = val;
  });
  effect(() => {
    if (!_hashParamsInitialized) {
      return;
    }
    const val = s.value;
    const existing = hashParamsSignal.peek().get(key);
    if (val !== existing) {
      updateHashParams({
        [key]: val
      });
    }
  });
  return s;
};
export const singleBoolHashParamSignal = (key: string) => {
  const s = signal<boolean>(false);
  effect(() => {
    const val = hashParamsSignal.value.has(key);
    s.value = val;
  });
  effect(() => {
    const val = s.value;
    updateHashParams({
      [key]: val
    });
  });
  return s;
};
let _hashParamsInitialized = false;
export function WithHashParams({
  children
}: {
  children: ReactNode;
}) {
  const _router = useRouter();
  useEffect(() => {
    function updateHashParams() {
      const hash = window.location.hash.substring(1);
      const params = new URLSearchParams(hash);
      if (params.toString() === hashParamsSignal.value.toString()) {
        return;
      }
      _hashParamsInitialized = true;
      hashParamsSignal.value = params;
    }
    function deferredUpdateHashParams() {
      setTimeout(updateHashParams, 0);
    }
    updateHashParams();
    window.addEventListener("hashchange", updateHashParams);
    window.addEventListener("popstate", updateHashParams);
    window.addEventListener("urlchange", deferredUpdateHashParams);
    return () => {
      window.removeEventListener("hashchange", updateHashParams);
      window.removeEventListener("popstate", updateHashParams);
      window.removeEventListener("urlchange", deferredUpdateHashParams);
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      initDeferred();
      initDeferedSignals();
    }, 50);
  }, []);
  const hash = useHash();
  useEffect(() => {
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    if (params.toString() === hashParamsSignal.value.toString()) {
      return;
    }
    _hashParamsInitialized = true;
    hashParamsSignal.value = params;
  }, [hash]);
  return <>{children}</>;
}

// Based on https://github.com/vercel/next.js/discussions/49465
const getHash = () => typeof window !== "undefined" ? window.location.hash : "";
const useHash = () => {
  const [hash, setHash] = useState(getHash());
  // const params = useParams();
  // While useParams() has fired on hash changes, it doesn't seem to do so consistently any longer.
  // But this does seem to fire consistently:
  const _temp = useSelectedLayoutSegments();
  useEffect(() => {
    setHash(getHash());
  }, [setHash]);
  return hash;
};