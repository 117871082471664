import { effect, signal } from "@preact/signals-react";

export interface SignalType<T> {
  value: T;
  peek: () => T;
}

let _deferedInit: (() => void)[] | null = [];

export function promiseUntilDefered<T>(promise: Promise<T>) {
  if (_deferedInit === null) {
    return promise;
  }
  return new Promise<T>((resolve, reject) => {
    _deferedInit!.push(() => {
      promise.then(resolve, reject);
    });
  });
}

export function initDeferedSignals() {
  if (!_deferedInit) {
    return;
  }
  for (const fn of _deferedInit) {
    fn();
  }
  _deferedInit = null;
}

export function persistentSignal<T>(
  name: string,
  defaultValue: T,
  options: { sessionStorage?: boolean } = {}
): SignalType<T> {
  let initializedFromStorage = false;
  if (typeof window === "undefined") {
    // On the server
    return signal(defaultValue);
  }
  const s = signal<T>(defaultValue);
  const storage =
    options && options.sessionStorage
      ? window.sessionStorage
      : window.localStorage;
  if (!name || typeof name !== "string") {
    throw new Error("name must be a string");
  }
  const updateFromStorage = () => {
    const value = storage.getItem(`signal.${name}`);
    let result: T;
    if (value) {
      result = JSON.parse(value);
    } else {
      result = defaultValue;
    }
    s.value = result;
    initializedFromStorage = true;
  };
  if (_deferedInit) {
    _deferedInit.push(updateFromStorage);
  } else {
    updateFromStorage();
  }
  effect(() => {
    const v = s.value;
    if (!initializedFromStorage) {
      return;
    }
    try {
      storage.setItem(`signal.${name}`, JSON.stringify(v));
    } catch (e) {
      console.error("Error saving signal", name, v, e);
      throw e;
    }
  });
  return s;
}

export class SignalView<T> implements SignalType<T> {
  private keys: string[];

  constructor(
    public signal: SignalType<any>,
    key: string | string[],
    public defaultValue: T,
    public onChange?: (v: T) => void
  ) {
    this.signal = signal;
    this.keys = Array.isArray(key) ? key : [key];
    this.defaultValue = defaultValue;
  }

  private getNestedValue(obj: any): T {
    if (!obj || typeof obj !== "object") {
      console.warn("SignalView encountered non-object value:", obj, this.keys);
      return this.defaultValue;
    }

    let current = obj;
    for (const key of this.keys) {
      if (!current || typeof current !== "object") {
        return this.defaultValue;
      }
      current = current[key];
      if (current === undefined) {
        return this.defaultValue;
      }
    }
    return current as T;
  }

  private setNestedValue(obj: any, value: T): any {
    if (!obj || typeof obj !== "object") {
      console.warn("SignalView encountered non-object value:", obj, this.keys);
      throw new Error("SignalView on signal with non-object value");
    }

    if (this.keys.length === 1) {
      return {
        ...obj,
        [this.keys[0]]: value,
      };
    }

    const current = { ...obj };
    let pointer = current;
    for (let i = 0; i < this.keys.length - 1; i++) {
      const key = this.keys[i];
      pointer[key] = { ...(pointer[key] || {}) };
      pointer = pointer[key];
    }
    pointer[this.keys[this.keys.length - 1]] = value;
    return current;
  }

  get value() {
    return this.getNestedValue(this.signal.value);
  }

  set value(v: T) {
    this.signal.value = this.setNestedValue(this.signal.value, v);
    if (this.onChange) {
      this.onChange(v);
    }
  }

  peek() {
    return this.getNestedValue(this.signal.peek());
  }
}
