import React from "react";
import { signal } from "@preact/signals-react";
import { SignalType } from "@/lib/signals";
import { AsyncLoaderComponent } from "./loadercomponent";

export type AsyncLoadStatusType =
  | "init"
  | "loading"
  | "refreshing"
  | "error"
  | "ready";

export class AsyncLoad<T> {
  version: SignalType<number>;
  loadedValue: SignalType<T | null>;
  status: SignalType<AsyncLoadStatusType>;
  error: SignalType<Error | null>;

  constructor(public loadFunc: () => Promise<T>) {
    this.loadFunc = loadFunc;
    this.version = signal(0);
    this.loadedValue = signal<T | null>(null);
    this.status = signal<AsyncLoadStatusType>("init");
    this.error = signal<Error | null>(null);
    this.Progress = this.Progress.bind(this);
    this.init();
  }

  async refresh(status: AsyncLoadStatusType = "refreshing") {
    let v: T;
    this.status.value = "refreshing";
    try {
      v = await this.loadFunc();
      this.status.value = "ready";
    } catch (e) {
      this.status.value = "error";
      this.error.value = e as Error;
      throw e;
    }
    this.loadedValue.value = v;
  }

  private async init() {
    let v: T;
    deferredInitPromise.then(() => {
      if (this.status.value === "init") {
        this.status.value = "loading";
      }
    });
    try {
      v = await this.loadFunc();
    } catch (e) {
      this.status.value = "error";
      this.error.value = e as Error;
      return;
    }
    this.loadedValue.value = v;
    this.status.value = "ready";
  }

  Progress() {
    return React.createElement(AsyncLoaderComponent, {
      status: this.status,
      error: this.error,
    });
  }

  useProgress():
    | { data: null; component: NonNullable<React.ReactNode> }
    | { data: T; component: null } {
    if (this.loadedValue.value !== null) {
      return { data: this.loadedValue.value, component: null };
    }
    return { data: null, component: React.createElement(this.Progress) };
  }
}

let _deferredInit: (() => void)[] | null = [];

const deferredInitPromise = new Promise<void>((resolve) => {
  if (_deferredInit) {
    _deferredInit.push(resolve);
  } else {
    resolve();
  }
});

export function promiseUntilDeffered<T>(promise: Promise<T>) {
  if (_deferredInit === null) {
    return promise;
  }
  return new Promise<T>((resolve, reject) => {
    _deferredInit!.push(() => {
      promise.then(resolve, reject);
    });
  });
}

export function initDeferred() {
  if (!_deferredInit) {
    return;
  }
  for (const fn of _deferredInit) {
    fn();
  }
  _deferredInit = null;
}
